<template>
  <div
    class="navbar-item is-hidden-tablet-only is-hidden-desktop-only"
    v-if="isUser"
  >
    <router-link
      v-if="hasStockview"
      @click.native="track"
      class="nav-menu-item option"
      :to="{ name: 'stockview' }"
      data-test-id="stock-overview-button"
    >
      <span>Stock overview</span>
    </router-link>
    <router-link
      v-if="hasStockview"
      @click.native="track"
      class="nav-menu-item option"
      :to="{ name: 'stockview-stock' }"
      data-test-id="stock-analysis-button"
    >
      <span>Stock analysis</span>
    </router-link>
    <router-link
      @click.native="track"
      class="nav-menu-item option"
      :to="{ name: 'root' }"
      data-test-id="price-check-button"
    >
      <span>Price check</span>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppHeaderEndLinks',
  computed: {
    ...mapGetters('auth', [
      'isUser',
      'branch',
      'hasStockview'
    ])
  }
}
</script>

